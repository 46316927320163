<template>
  <div>
    <router-view></router-view>
    <notifications position="bottom right" />
  </div>
</template>
<script>
import * as authService from './service/auth'
import './style.scss'

export default {
  name: 'App',
  mounted () {
    this.checkAuth()
  },
  watch: {
    $route() {
      this.checkAuth()
    }
  },
  methods: {
    async checkAuth () {
      try {
        if (!(window.location.pathname.startsWith('/login') || window.location.pathname.startsWith('/register'))) {
          const user = await authService.verifyToken()
          if (window.location.pathname.startsWith('/site-permission') && !user.is_super_admin) {
            console.log('err: not super admin');
            window.location.href = '/'
          }
        }
      } catch (err) {
        console.log('err: ', err);
        window.location.href = '/login'
      }
    }
  }
}
</script>
